import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import PageNotFound from "../pages/404";
import Login from "../pages/login";
import AddUser from "../pages/adduser";
import UserList from "../pages/userlist";
import Profile from "../pages/profile";
import ProductList from "../pages/productlist";
import AddProduct from "../pages/addproduct";
import LoginPageIsLogedIn from "../components/loginProtected";
import ProtectedRoute from "../components/protectedRoute";
import ForgetPassword from "../pages/forget-password";
import Forgget from "../pages/forget-password/Forgget";
import ResetPassword from "../pages/reset-password";
import AddSeller from "../pages/addseller";
import SellerList from "../pages/sellerlist";
import Roles from "../pages/roles";
import OrderList from "../pages/orderlist";
import Category from "../pages/category";
import UpdateSeller from "../pages/updateseller";
import Tax from "../pages/tax";
import UpdateUser from "../pages/updateuser";
import Location from "../pages/location";
import Logs from "../pages/logs";
import Transaction from "../pages/transaction";
// import OrderDetails from "../pages/order-details";
import Mastercatalog from "../pages/catalog/Mastercatalog";
import LogRejection from "../pages/catalog/logRejection";
import MasterProductAdd from "../pages/NewmasterPage/MasterProductAdd";
import MasterProductList from "../pages/NewmasterPage/MasterProductList";
import OndcSellerProduct from "../pages/NewmasterPage/OndcSellerProduct";
import OndcSellerProductAdd from "../pages/NewmasterPage/OndcSellerProductAdd";
import NonapprovedProducts from "../pages/Non Approved Product List/index";
import NonapprovedProduct from "../pages/NonApprovedProduct/index";
import Notification from "../pages/Notifications/index.js";
import Sellerdetails from "../pages/SellerDetails";
import SellerNonApprovedlist from "../pages/Non Approved Product List/SellerNonApprovedlist";
import Onboarding from "../pages/Onboarding";
import Registerseller from "../pages/SellerRegisteration";
import Personaldetails from "../pages/SellerRegisteration/Personaldetails";
import Bankdetails from "../pages/SellerRegisteration/Bankdetails";
import Checkseller from "../pages/SellerRegisteration/Checkseller";
import Pendingpage from "../pages/SellerRegisteration/Pendingpage";
import Brand from "../pages/Brand";
import Addcategory from "../pages/New Category/Addcategory";
import Sellerauthcheck from "../pages/SellerRegisteration/Sellerauthcheck";
import Seeorderdetails from "../pages/PartialOrder/Seeorderdetails";
import Acceptedorderdetails from "../pages/PartialOrder/Acceptedorderdetails";
import Orderpopusp from "../pages/PartialOrder/Orderpopusp";
import Instorecatalog from "../pages/catalog/Instorecatalog";
import CreateProduct from "../pages/catalog/createProduct";
import Invoice2 from "../pages/Invoice/Invoice2";
// ondc
import AllOrderList from "../pages/allorderslist";

import Sidebar from "../components/thememaker/sidebar";
import Catalog from "../pages/catalog";
import Order from "../pages/order";
import OrderCompleteDetail from "../pages/order/orderCompleteDetail";
import OrderCompleteinfo from "../pages/order/orderCompleteinfo";
import ReturnOrders from "../pages/returnorders";
import CancelOrderlist from "../pages/order/cancelOrderlist";
import Invoice from "../pages/Invoice/Invoice";
import Issues from "../pages/Issues";
import OnIssues from "../pages/onIssue";
import SupportList from "../pages/support"
import Rspdashboard from "../pages/RSP/Rspdashboard";
import UpdatePassword from "../pages/Update Password/UpdatePassword";
import Settings from "../pages/Settings/Settings";
import Serviceability from "../pages/Serviceablity/Serviceability";
import Variants from "../components/Variants/Variants";
import Discount from "../pages/Discount/Discount";
import OrderAmountDiscount from "../components/Discount/OrderAmountDiscount"
import Addroducttype from "../pages/producttype/Addroducttype";
import Productlist from "../pages/producttype/Productlist";
import Unsettledtransction from "../pages/unsettledTransaction";
import Payments from "../pages/payments";
import Return from "../pages/returnPayment";
import CustomerOrder from "../pages/order/customerOrder";
import CompleteOrder from "../pages/order/completeOrder";
import Xtraasettings from "../pages/xtraasettings/Xtraasettings";
import Addshipping from "../pages/shipping/Addshipping";
import Shippingchargelist from "../pages/shipping/Shippingchargelist";
import Socialtagging from "../pages/social_tagging/Socialtagging";
import Form1 from "../pages/Investment/Insaurance/Form1";

const routes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/",
    component: <Login />,
  },
  {
    path: "/addseller",
    component: <AddSeller />,
  },
  {
    path: "/sellerlist",
    component: <SellerList />,
  },
  {
    path: "/tax",
    component: <Tax />,
  },
  {
    path: "/updateseller/:id",
    component: <UpdateSeller />,
  },
  {
    path: "/roles",
    component: <Roles />,
  },
  {
    path: "/adduser",
    component: <AddUser />,
  },
  {
    path: "/updateuser/:id",
    component: <UpdateUser />,
  },
  {
    path: "/userlist",
    component: <UserList />,
  },
  {
    path: "/category",
    component: <Category />,
  },
  {
    path: "/product/:id?",
    component: <AddProduct />,
  },
  {
    path: "/productlist",
    component: <ProductList />,
  },
  {
    path: "/orderlist",
    component: <OrderList />,
  },
  // {
  //   path: "/orderdetails/:id?",
  //   component: <OrderDetails />,
  // },
  {
    path: "/transaction",
    component: <Transaction />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/location",
    component: <Location />,
  },
  {
    path: "/forget-password",
    component: <ForgetPassword />,
  },
  {
    path: "/reset-password/:id",
    component: <ResetPassword />,
  },
  {
    path: "/resetpassword",
    component: <Forgget />,
  },
  {
    path: "/logs",
    component: <Logs />,
  },

  {
    path: "/mastercatalog/:id?",
    component: <MasterProductAdd />,
  },
  {
    path: "/masterproductlist",
    component: <MasterProductList />,
  },
  {
    path: "/ondcsellerproduct",
    component: <OndcSellerProduct />,
  },
  {
    path: "/ondcsellerproductadd/:id?",
    component: <OndcSellerProductAdd />,
  },
  {
    path: "/nonapprovedproducts",
    component: <NonapprovedProducts />,
  },
  {
    path: "/nonapprovedproduct/:id?",
    component: <NonapprovedProduct />,
  },
  {
    path: "/notifications",
    component: <Notification />,
  },
  {
    path: "/sellernonapprovedproducts",
    component: <SellerNonApprovedlist />,
  },

  // {
  //   path: "/test",
  //   component: <Something />
  // },

  {
    path: "/sidebar",
    component: <Sidebar />,
  },
  {
    path: "/sellerdetail",
    component: <Sellerdetails />,
  },
  {
    path: "/ondcsellerapp",
    component: <Onboarding />,
  },
  {
    path: "/selleregister",
    component: <Registerseller />,
  },
  {
    path: "/personaldetails/:id?",
    component: <Personaldetails />,
  },
  {
    path: "/bankdetails/:id?",
    component: <Bankdetails />,
  },
  {
    path: "/checkseller",
    component: <Checkseller />,
  },

  {
    path: "/pending",
    component: <Pendingpage />,
  },
  {
    path: "/catalog",
    component: <Catalog />,
  },
  {
    path: "/logrejection",
    component: <LogRejection />,
  },

  {
    path: "/brand",
    component: <Brand />,
  },
  {
    path: "/newcategory",
    component: <Addcategory />,
  },
  {
    path: "/sellerauthcheck",
    component: <Sellerauthcheck />,
  },
  {
    path: "/seeorderdetails",
    component: <Seeorderdetails />,
  },
  {
    path: "/order",
    component: <Order />,
  },
  {
    path: "/returnorders",
    component: <ReturnOrders />,
  },
  {
    path: "/orderCompleteDetail/:id?",
    component: <OrderCompleteDetail />,
  },
  {
    path: "/customerorder",
    component: <CustomerOrder />,
  },
  {
    path: "/completeOrder",
    component: <CompleteOrder />,
  },
  {
    path: "/cancelOrderlist",
    component: <CancelOrderlist />,
  },
  {
    path: "/orderCompleteinfo/:id?",
    component: <OrderCompleteinfo />
  },
  {
    path: "/orderpopup",
    component: <Orderpopusp />,
  },
  {
    path: "/updatestoreproduct/:id?",
    component: <CreateProduct />,
  },
  {
    path: "/acceptedorderdetail",
    component: <Acceptedorderdetails />,
  },
  {
    path: "/invoice/:order_id?",
    component: <Invoice />,
  },
  {
    path: "/order_invoice/:order_id?",
    component: <Invoice2 />,
  },
  //ondc order list
  {
    path: "/allorderslist",
    component: <AllOrderList />,
  },
  //rsp
  {
    path: "/rspdashboard",
    component: <Rspdashboard />,
  },
  // IGM 
  {
    path: "/issues",
    component: <Issues />
  },
  {
    path: "/onissues",
    component: <OnIssues />
  },
  {
    path: "/supportlist",
    component: <SupportList />
  },
  {
    path: "/updatepassword",
    component: <UpdatePassword />
  },
  {
    path: "/settings",
    component: <Settings />,
  },
  {
    path: "/serviceability",
    component: <Serviceability />,
  },
  {
    path: "/variants",
    component: <Variants />,
  },
  {
    path: "/discount",
    component: <Discount />,
  },
  {
    path: "/discountbyOrderAmount",
    component: <OrderAmountDiscount />,
  },
  {
    path: "/producttype/:id?",
    component: <Addroducttype />,
  },
  {
    path: "/producttypelist",
    component: <Productlist />,
  },
  {
    path: "/unsettledtransactions",
    component: <Unsettledtransction />,
  },
  {
    path: "/productcatalog",
    component: <Mastercatalog />,
  },
  {
    path: "/addinstoreproduct/:id?",
    component: <CreateProduct />,
  },
  {
    path: "/instoreproducts",
    component: <Instorecatalog />,
  },
  {
    path: "/payments",
    component: <Payments />,
  },
  {
    path: "/return",
    component: <Return />,
  },
  {
    path: "/adminsettings",
    component: <Xtraasettings />,
  },
  {
    path: "/addshippingcharge",
    component: <Addshipping />,
  },
  {
    path: "/shippingchargelist",
    component: <Shippingchargelist />,
  },
  {
    path: "/socialtagging",
    component: <Socialtagging />,
  },
  {
    path: "/health/insaurance/:id?",
    component: <Form1 />,
  },
  {
    path: "*",
    component: <PageNotFound />,
  },
];
// console.log("LoginPageIsLogedIn----", LoginPageIsLogedIn);
const AllRoutes = () => {
  return (
    <>
      <Router>
        <Switch>
          {routes.map((item, index) => (
            <Route exact path={item?.path} key={index}>
              {item.path === "/" ? (
                <LoginPageIsLogedIn>{item.component}</LoginPageIsLogedIn>
              ) : item.path === "/ondcsellerapp" ? (
                <Onboarding />
              ) : item.path === "/checkseller" ? (
                <Checkseller />
              ) : item.path === "/health/insaurance/:id?" ? (
                <Form1 />
              ) : item.path === "/resetpassword" ? (<Forgget />) : item.path === "/order_invoice/:order_id?" ? (
                <Invoice2 />
              ) : item.path === "/order_invoice/:order_id?" ? (
                <Invoice2 />
              ) : item.path === "/sellerauthcheck" ? (
                <Sellerauthcheck />
              ) : item.path === "/acceptedorderdetail" ? (
                <Acceptedorderdetails />
              ) : item.path === "/seeorderdetails" ? (
                < Seeorderdetails />
              ) : (
                <>
                  <ProtectedRoute>{item.component}</ProtectedRoute>
                </>
              )}
            </Route>
          ))}
        </Switch>
      </Router>
    </>
  );
};

export default AllRoutes;
